<template>
  <div class="container-fluid pr-lg-5 pl-xl-5" style="margin-top: 30px !important;">
    <div class="col-md-10 col-lg-8 col-xl-7 text-center p-0 mx-auto">
      <h1 class="display-4 font-secondary font-weight-semibold mb-4">
        Thank You!
      </h1>
      <div class="lead text-muted mb-5">
        Self-service request has been approved successfully.
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AppSelfServiceThankYou",
  data: () => ({
    requestSending: false,
    request: {
      tenantId: '',
      requestType: '',
      trafficDirection: '',
      retention: '',
      ipList: '',
    },
    state: {
      tenantId: null,
      requestType: null,
      trafficDirection: null,
      retention: null,
      ipList: null,
    }
  })
};
</script>
